import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Grid, Divider } from '@mui/material'
import { screenSizes } from 'shared/styles/theme'
import HomeIcon from '@mui/icons-material/Home';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ActionCard, CardBText, ImageAndText, CardSubText } from './style'
import ProductBox from './components/ProductBox';
import { getAmazonJPN_mongo, checkAndAdd_userAndId, recordUserAndIdsToRecordTableWhenVisitProductPage, get_menu_data } from 'modules/api/market-api'
import { setCurrentProductUrl, setCurrentGclidAndCurrentSearch } from 'logic/action/base.action'
import MobileDiv from './MobileDiv'
import history from 'modules/app/components/history'
// import TermsOfUse from './components/TermsOfUse'
// import PrivacyPolicy from './components/PrivacyPolicy'
// import Data from './components/Data'
import TrustBox from './components/TrustBox'
import OverviewBox from './components/OverviewBox'
import BuyersGuideBox from './components/BuyersGuideBox'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress, {
    CircularProgressProps,
  } from '@mui/material/CircularProgress';
import './base_new.css'

function generateRandomNumbers(flag:string, count: number) {
    const randomNumbers = [];
    
    // Generate the specified number of random numbers
    for (let i = 0; i < count; i++) {
        let randomNumber = 0
        if (flag === 'pick') {
            randomNumber = Math.floor(Math.random() * (299 - 100 + 1)) + 100;
        } else if (flag === 'review') {
            randomNumber = Math.floor(Math.random() * (2999 - 700 + 1)) + 700;
        }
        
        randomNumbers.push(randomNumber);
    }

    if (flag === 'pick') {
        // Sort the array in decreasing order
        randomNumbers.sort((a, b) => b - a);
    } else if (flag === 'review') {

    }

    return randomNumbers;
}

function formatNumber(num: number) {
    return num.toLocaleString('en-US'); // Formats the number with commas in US locale
}


const Base_New = (props: any) => {
    const dispatch = useDispatch()
    const selector = useSelector((state: any) => state)
    const location = useLocation()
    const { product_url } = useParams<{ product_url: string }>();

    const [ items, setItems ] = useState<any>([])
    const [ title, setTitle ] = useState<string>('')
    const [ product, setProduct ] = useState<string>('')
    const [ overview, setOverview ] = useState<string>('')
    const [ buyer_guide, setBuyerGuide ] = useState<string>('')
    const [ buyer_guide_title, setBuyerGuideTitle ] = useState<string>('')
    const [ buyer_guide_image_url, setBuyerGuideImage ] = useState<string>('')
    const [ evaluated_number, setEvaluatedNumber ] = useState<string>("0")
    const [ review_number, setReviewNumber ] = useState<string>("0")
    const [ isLoading, setIsLoading ] = useState<boolean>(false)
    const [ menu_data, setMenuData ] = useState<any>([])
    const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);
    const [isHoverDisabled, setIsHoverDisabled] = useState<boolean>(false);
    const tooltipRef = useRef<HTMLDivElement | null>(null);
   
    const e_func = () => {
        const e_num = 889
        let start = 0
        let start1 = 0
        const end = parseInt(String(e_num).substring(0,2))
        const end1 = parseInt(String(e_num).substring(2))

        if( start === end || start1 === end1) return;

        let timer = setInterval(() => {
            if( start < end ) {
                start += 3
            }
            if ( start1 < end1 ) {
                start1 += 1
            }
            
            setEvaluatedNumber(String(start) + String(start1))
            if( start >= end && start1 >= end1 ) {
                setEvaluatedNumber(String(end) + String(end1)) 
                clearInterval(timer)
            }    
        }, 10)
    }

    useEffect(() => {
        (async () => {
            let link = window.location.href
            let search = location.search
            let gclid = location.search
            let msclkid = location.search
    
            // console.log(link)
            // console.log(search)
            // console.log(gclid)
            // console.log(msclkid)
    
            if(gclid.indexOf('gclid=') === -1) {
                gclid = '0'
            } else {
                gclid = gclid.slice(gclid.indexOf('gclid=') + 6)
                if(gclid.indexOf('&') !== -1){
                    gclid = gclid.slice(0, gclid.indexOf('&'))
                }
            }
    
            if(msclkid.indexOf('msclkid=') === -1) {
                msclkid = '0'
            } else {
                msclkid = msclkid.slice(msclkid.indexOf('msclkid=') + 8)
                if(msclkid.indexOf('&') !== -1){
                    msclkid = msclkid.slice(0, msclkid.indexOf('&'))
                }
            }
    
            // dispatch(setCurrentGclidAndCurrentSearch(gclid, msclkid, search))
    
            localStorage.setItem('gclid', gclid)
            localStorage.setItem('msclkid', msclkid)
            localStorage.setItem('search', search)
            localStorage.setItem('link', link)
    
            if(product_url !== undefined && product_url !== '' && product_url !== 'admin' ){
                if ( product_url === 'categories') {
                    let menu_data_result = await get_menu_data()
                    // console.log(menu_data_result.menu_data)
                    setMenuData(menu_data_result.menu_data)
                } else {
                    // setIsLoading(true)
                    checkAndAdd_userAndId()
                    .then(result => {
                        getAmazonJPN_mongo(product_url)
                            .then((result:any) => {
                                // console.log('result.data.items')
                                // console.log(product_url)
    
                                let gclid = localStorage.getItem('gclid')
                                let msclkid = localStorage.getItem('msclkid')
                                let search = localStorage.getItem('search')
                                let amazon_tracking_id = localStorage.getItem('amazon_tracking_id')
                                let _id = localStorage.getItem('_id')
                                recordUserAndIdsToRecordTableWhenVisitProductPage(gclid, msclkid, amazon_tracking_id, 'https://productwiz.co/' + product_url, search, _id, {})
                                    .then(result => {
                                    })
                                    .catch(err => {
                                        // console.log(err)
                                    })
    
    
    
                                let items = result.data.items
                                let sortedRandomNumbersArray:number[] = []
                                let sortedRandomNumbersArray_02:number[] = []
    
                                if (items.length !== 0) {
                                    sortedRandomNumbersArray = generateRandomNumbers('pick', items.length);
                                    sortedRandomNumbersArray_02 = generateRandomNumbers('review', items.length);
                                }
                                
                                items = items.map((item: any, index: number) => {
                                    console.log(item.tag_option)

                                    if (item.tag_option === true) {
                                        if(item.DetailPageURL.indexOf("tag=") !== -1){
                                            let temp_str = item.DetailPageURL.substring(item.DetailPageURL.indexOf("tag="));
                                            let length = temp_str.indexOf("&")
                                            let temp_str1;
                                            
                                            if(length === -1){
                                                temp_str1 = item.DetailPageURL.substring(item.DetailPageURL.indexOf("tag="))
                                            } else if(length !== -1){
                                                temp_str1 = item.DetailPageURL.substr(item.DetailPageURL.indexOf("tag="), length)
                                            }
                                            
                                            item.DetailPageURL = item.DetailPageURL.replace(temp_str1, "tag=" + localStorage.getItem('amazon_tracking_id'))
                                            
                                            
                                        } else if(item.DetailPageURL.indexOf("tag=") === -1) {
                                            item.DetailPageURL = item.DetailPageURL + "&tag=" + localStorage.getItem('amazon_tracking_id')
                                        }
                                    } else if (item.tag_option === false || item.tag_option === undefined) {
                                        console.log(item.DetailPageURL)
                                        if(item.DetailPageURL.indexOf("tag=") !== -1){
                                            let temp_str = item.DetailPageURL.substring(item.DetailPageURL.indexOf("tag="));
                                            let length = temp_str.indexOf("&")
                                            let temp_str1;
                                            
                                            if(length === -1){
                                                temp_str1 = item.DetailPageURL.substring(item.DetailPageURL.indexOf("tag="))
                                            } else if(length !== -1){
                                                temp_str1 = item.DetailPageURL.substr(item.DetailPageURL.indexOf("tag="), length)
                                            }
                                            
                                            item.DetailPageURL = item.DetailPageURL.replace(temp_str1, "")
                                            
                                            
                                        } else if(item.DetailPageURL.indexOf("tag=") === -1) {

                                        }
                                        console.log(item.DetailPageURL)
                                    }
                                    
    
                                    item = Object.assign(item, { picked_users_count: sortedRandomNumbersArray[index] })
                                    item = Object.assign(item, { reviews_count_r: formatNumber(sortedRandomNumbersArray_02[index]) })
    
                                    return item
                                })
                                // setItems(result.data.items)
                                setItems(items)
                                setTitle(result.data.family_name)
                                setProduct(result.data.product_name)
                                setOverview(result.data.overview)
                                setBuyerGuide(result.data.buyer_guide)
                                setBuyerGuideTitle(result.data.buyer_guide_title)
                                setBuyerGuideImage(result.data.buyer_guide_image_url)
                                
                                // setIsLoading(false)
    
                                // e_func()
                                // r_func()
                            })
                    })
                }
                
            }
    
                // if(product_url !== 'terms-of-use' && product_url !== 'privacy-policy' && product_url !== 'data' && product_url !== 'admin') {
                //     dispatch(setCurrentProductUrl(product_url))
                // }
        })()
        
    }, [product_url])

    const goStartPage = () => {
        history.push(`/`)
    }


    

    const handleClick = (e: React.MouseEvent): void => {
        e.stopPropagation();
        setIsHoverDisabled(true);
        // setIsTooltipVisible((prev) => !prev);
        setIsTooltipVisible(true);
      };
    
    // Enables the hover behavior when clicking outside
    const handleClickOutside = (e: MouseEvent): void => {
        if (
            tooltipRef.current &&
            !tooltipRef.current.contains(e.target as Node)
        ) {
            setIsTooltipVisible(false);
            setIsHoverDisabled(false);
        }
    };

    const click_got_it = () => {
        setIsTooltipVisible(false);
        setIsHoverDisabled(false);
    }

    // Set up a global click listener to hide the tooltip
    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);


    return (
        <>
            {
                // product_url === 'terms-of-use' ? <TermsOfUse />
                // : product_url === 'privacy-policy' ? <PrivacyPolicy />
                //  product_url === 'data' ? <Data />
                product_url === 'admin' ? <></> 
                : product_url === 'categories' 
                ? <>
                    <CustomWrapperCategories>
                        <CustomGrid container spacing={0} style={{maxWidth: '1320px'}} className=''>
                            <CustomGrid item container spacing={0} xs={12} md={9} className="grid-header-left grid-flex-item-start category_mobile_pd">
                                <CustomGrid item container spacing={0} className="margin-padding-0_v-center">
                                    <CustomGrid item sm={12} className="margin-padding-0_v-center">
                                        <ShortSpan onClick={goStartPage}>
                                            <HomeIcon  />
                                        </ShortSpan>
                                        <span style={{color: 'gray'}}>&nbsp;&nbsp;✦&nbsp;&nbsp;</span>
                                        <ShortSpan>
                                            All Categories
                                        </ShortSpan>
                                    </CustomGrid>
                                </CustomGrid>
                                <CustomGrid item container spacing={0} className="margin-padding-0_v-center">
                                    <CustomGrid item  sm={12} className="margin-padding-0_v-center">
                                        <CategoryTitle>All Categories</CategoryTitle>
                                    </CustomGrid>
                                </CustomGrid>
                            </CustomGrid>
                            <CustomGrid item xs={12} md={3} className="grid-header-right grid-flex-item-end advertising_grid">
                                <div className='advertising_grid_wrapper'>
                                    <div 
                                        className='advertising_grid_text'
                                        onMouseEnter={() => !isHoverDisabled && setIsTooltipVisible(true)}
                                        onMouseLeave={() => !isHoverDisabled && setIsTooltipVisible(false)}
                                        onClick={handleClick}
                                    >
                                        Advertising Disclosure &nbsp;
                                        <img src={require('assets/arrow_bottom_buyer.svg').default} />
                                    </div>
                                    {
                                        isTooltipVisible && (
                                            <div className='advertising_text_tooltip'  ref={tooltipRef}>
                                                <p>This site is a free online resource that strives to offer helpful content and comparison features to its visitors. Please be advised that the operator of this site accepts advertising compensation from companies that appear on the site, and such compensation  impacts the location and order in which the companies (and/or their products).</p>
                                                <p>In addition, the Operating Agreement requires that the following statement clearly and conspicuously appears on your Site: &quot;As an Amazon Associate I earn from qualifying purchases.&quot; For social media user-generated content, this statement must be associated with your account.</p>  
                                                <div className='advertising_got_it_btn' onClick={click_got_it}>
                                                    GOT IT
                                                </div>
                                            </div>
                                        )
                                    }
                                    
                                </div>
                            </CustomGrid>
                        </CustomGrid> 
                        {/* <Divider sx={{width: '100%', boxShadow: '0px 1px 2px #0a0909'}} /> */}
                        <CustomGrid container spacing={0} className='category_mobile_pd' sx={{background: 'transparent!important', justifyContent: 'center', maxWidth: '1320px'}}>
                            <CustomGrid item container spacing={0} sm={12} className="grid-header-right grid-flex-item-start category_mobile_pd" sx={{background: 'transparent!important'}} >
                                {
                                    menu_data.length > 0 && menu_data.map((item: any, index: number) => {
                                        return (
                                            <CustomGrid item xs={6} md={3} className="category_sub_div" key={index}>
                                                <div className='category_item_wrapper'>
                                                    <div className='category_img_holder'>
                                                        {
                                                            item.title._id === 'Auto' ? 
                                                            <img alt="Electronics" className="img-fluid rounded" src={require('assets/category_images/auto.jpg').default} />
                                                            : item.title._id === 'Entertainment' ?
                                                            <img alt="Electronics" className="img-fluid rounded" src={require('assets/category_images/entertainment.jpg').default} />
                                                            : item.title._id === 'Health' ?
                                                            <img alt="Electronics" className="img-fluid rounded" src={require('assets/category_images/health.jpg').default} />
                                                            : item.title._id === 'Home' ?
                                                            <img alt="Electronics" className="img-fluid rounded" src={require('assets/category_images/home.jpg').default} />
                                                            : item.title._id === 'Tech' ?
                                                            <img alt="Electronics" className="img-fluid rounded" src={require('assets/category_images/tech.jpg').default} />
                                                            : <img alt="Electronics" className="img-fluid rounded" src="https://d1ccm0vckus47s.cloudfront.net/asset/34601/preview_large_electronics.png" />
                                                        }
                                                        
                                                    </div>

                                                    <h3 className="category_item_title_h3">{item.title._id}</h3>

                                                    <ul className='category_item_list_unstyled'>
                                                        {

                                                            item.options.length > 0 && item.options.map((option: any, index1: number) => {
                                                                return (
                                                                    <li key={index1}>
                                                                        <a className="child-link" href={"/" + option.product_name?.toLowerCase().split(' ').join('-')}>{option.product_name}</a>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </CustomGrid>
                                        )
                                    })
                                }

                            </CustomGrid>
                        </CustomGrid>
                    </CustomWrapperCategories> 
                </>
                : <>
                    {
                        isLoading  ? 
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={isLoading}
                            // onClick={handleClose}
                        >
                            <CircularProgress color="inherit"/>
                            <p>&nbsp;&nbsp; Loading ...</p>
                        </Backdrop> : <></>
                    }
                    <CustomWrapperItems>
                        <CustomGrid container spacing={0} style={{maxWidth: '1320px'}}>
                            <CustomGrid item container spacing={0} sm={9} className="grid-header-left grid-flex-item-start">
                                <CustomGrid item container spacing={0} className="margin-padding-0_v-center">
                                    <CustomGrid item sm={12} className="margin-padding-0_v-center">
                                        <ShortSpan onClick={goStartPage}>
                                            <HomeIcon  />
                                        </ShortSpan>
                                        <span style={{color: 'gray'}}>&nbsp;&nbsp;✦&nbsp;&nbsp;</span>
                                        <ShortSpan>
                                            {title}
                                        </ShortSpan>
                                        <span style={{color: 'gray'}}>&nbsp;&nbsp;✦&nbsp;&nbsp;</span>
                                        <ShortSpan className="lastSpan">
                                            {product}
                                        </ShortSpan>
                                    </CustomGrid>
                                </CustomGrid>
                                <CustomGrid item container spacing={0} className="margin-padding-0_v-center">
                                    <CustomGrid item  sm={12} className="margin-padding-0_v-center">
                                        <CategoryTitle>Best {product} <span style={{fontSize: '28px', fontWeight: '400'}}>(Last Updated December 2024)</span></CategoryTitle>
                                    </CustomGrid>
                                </CustomGrid>
                                <CustomGrid item container spacing={0} className="margin-padding-0_v-center">
                                    <CustomGrid item sm={12} className="margin-padding-0_v-center">
                                        <UpdatedSpan>{overview}</UpdatedSpan>
                                    </CustomGrid>
                                </CustomGrid>
                            </CustomGrid>
                            <CustomGrid item sm={3} className="grid-header-right grid-flex-item-end advertising_grid">
                                <div className='advertising_grid_wrapper'>
                                    <div 
                                        className='advertising_grid_text'
                                        onMouseEnter={() => !isHoverDisabled && setIsTooltipVisible(true)}
                                        onMouseLeave={() => !isHoverDisabled && setIsTooltipVisible(false)}
                                        onClick={handleClick}
                                    >
                                        Advertising Disclosure &nbsp;
                                        <img src={require('assets/arrow_bottom_buyer.svg').default} />
                                    </div>
                                    {
                                        isTooltipVisible && (
                                            <div className='advertising_text_tooltip'  ref={tooltipRef}>
                                                <p>This site is a free online resource that strives to offer helpful content and comparison features to its visitors. Please be advised that the operator of this site accepts advertising compensation from companies that appear on the site, and such compensation  impacts the location and order in which the companies (and/or their products).</p>
                                                <p>In addition, the Operating Agreement requires that the following statement clearly and conspicuously appears on your Site: &quot;As an Amazon Associate I earn from qualifying purchases.&quot; For social media user-generated content, this statement must be associated with your account.</p>  
                                                <div className='advertising_got_it_btn' onClick={click_got_it}>
                                                    GOT IT
                                                </div>
                                            </div>
                                        )
                                    }
                                    
                                </div>
                            </CustomGrid>
                        </CustomGrid> 
                        <Divider sx={{width: '100%', boxShadow: '0px 1px 2px #0a0909'}} />
                        <CustomGrid container spacing={0} sx={{background: 'transparent!important', marginTop: '20px!important', justifyContent: 'center', maxWidth: '1320px'}}>
                            <CustomGrid item container spacing={0} sm={12} className="grid-header-right grid-flex-item-start" sx={{background: 'transparent!important'}} >
                                {   items.length !== 0 ?
                                    items.length > 0 && items.map((item: any, index: number)=> {
                                        return <ProductBox key={index} item={item} num={index + 1}/>
                                    }
                                    ) : ''
                                }
                            </CustomGrid>
                        </CustomGrid>
                        <CustomGrid container spacing={0} sx={{background: 'transparent!important', marginTop: '20px!important', justifyContent: 'center', maxWidth: '1320px'}}>
                            <CustomGrid item container spacing={0} className="">
                                <CustomGrid item  sm={12} className="margin-padding-0_v-center" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <CategoryTitle>{product} Buyer's Guide</CategoryTitle>
                                    <span className='buyer_guide_title_sub'>{ buyer_guide_title }</span>
                                </CustomGrid>
                            </CustomGrid>
                            <CustomGrid item container spacing={0} className="">
                                <CustomGrid item sm={12} className="img_wrapper_grid">
                                    <div className="img_div_wrapper">
                                        <span className="img">
                                            {
                                                buyer_guide_image_url === undefined || buyer_guide_image_url === null || buyer_guide_image_url?.trim() === '' ?
                                                items.length > 0 ? 
                                                <img src={items[0].Images?.Primary?.Large?.URL} alt="" title="" />
                                                :  
                                                <></>
                                                // <img src="https://www.buyersreport.org/cdn-cgi/image/width=900,f=auto,quality=85,onerror=redirect/https://d1ccm0vckus47s.cloudfront.net//home/ubuntu/www/releases/20230913173000/tmp/uploads/1694634426-101040460748555-0001-6035/image.png" alt="" title="" />
                                                :
                                                <img src={buyer_guide_image_url} alt="" title="" />
                                            }

                                            {/* <img src="https://www.buyersreport.org/cdn-cgi/image/width=900,f=auto,quality=85,onerror=redirect/https://d1ccm0vckus47s.cloudfront.net//home/ubuntu/www/releases/20230913173000/tmp/uploads/1694634426-101040460748555-0001-6035/image.png" alt="" title="" /> */}

                                            
                                        </span>
                                    </div>
                                </CustomGrid>
                            </CustomGrid>
                            <CustomGrid item container spacing={0} className="">
                                <CustomGrid item sm={12} className="margin-padding-0_v-center">
                                    <UpdatedSpan>{buyer_guide}</UpdatedSpan>
                                </CustomGrid>
                            </CustomGrid>
                        </CustomGrid>
                    </CustomWrapperItems> 

                    <MobileDiv items={items} title={title} product={product} overview={overview} buyer_guide={buyer_guide} buyer_guide_title={buyer_guide_title} review_number={review_number} evaluated_number={evaluated_number} buyer_guide_image_url={buyer_guide_image_url} isHoverDisabled={isHoverDisabled} setIsTooltipVisible={setIsTooltipVisible} handleClick={handleClick} isTooltipVisible={isTooltipVisible} tooltipRef={tooltipRef} click_got_it={click_got_it}/>
                </>  
            }
        </>
    );
};

const CustomWrapperCategories = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    @media (max-width: ${screenSizes.mediaM}px) {
        // display: none;
    }
`

const CustomWrapperItems = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    @media (max-width: ${screenSizes.mediaM}px) {
        display: none;
    }
`



const CustomGrid = styled(Grid)`
&.advertising_grid {
    display: flex;
    justify-content: flex-end;
    // align-items: center;
    align-items: flex-end;

    div.advertising_grid_wrapper {
        position: relative;
    }

    div.advertising_grid_text {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 14px;
        color: rgb(113, 110, 118);
    }
    
    div.advertising_text_tooltip {
        right: -40%;
        top: 45px;
        position: absolute;
        z-index: 2;
        background-color: #f1efe9;
        width: 160%;
        padding: 5px 20px 15px;
        color: #000;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.3rem;
        text-align: center;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: default;

        @media (max-width: ${screenSizes.mediaM}px) {
            right: 0%;
        }
    }

    div.advertising_got_it_btn {
        padding: 5px 10px;
        background-color: #f38009;
        border-radius: 5px;
        color: white;
        width: 80%;
        cursor: pointer;
    }

    div.advertising_got_it_btn:hover {
        background-color: #f79e41;
       
    }
  }


  padding: 8px 20px;
  margin: 0px!important;

    &.category_mobile_pd {
        @media (max-width: ${screenSizes.mediaM}px) {
            // display: none;
            padding: 8px 0px!important;
        }
    }

  img {
    // width: 192px;
    // height: 48px;
  }

  .grid-flex-item-end {
    display: flex;
    justify-content: end;
  }

  .grid-flex-item-start {
    display: flex;
    justify-content: start;
    align-items: stretch;
    align-content: flex-start;
  }

  .grid-flex-item-start-overview {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
  }

  .grid-header-left {
    @media (min-width: ${screenSizes.mediaXLL}px) {
    //   padding-left: 105px!important;
    }
    @media (min-width: ${screenSizes.mediaXXL}px) {
    //   padding-left: 155px!important;
    }
    @media (min-width: ${screenSizes.mediaXXXX}px) {
    //   padding-left: 185px!important;
    }
  }

  .grid-header-right {
    
    @media (min-width: ${screenSizes.mediaXLL}px) {
    //   padding-right: 105px;
    //   padding-left: 105px;
    //   max-width: 1320px;
    }
    @media (min-width: ${screenSizes.mediaXXL}px) {
    //   padding-right: 155px;
    //   padding-left: 155px;
    //   max-width: 1320px;

    }
    @media (min-width: ${screenSizes.mediaXXXX}px) {
    //   padding-right: 185px;
    //   padding-left: 185px;
    //   max-width: 1320px;

    }
  }

 

  .custom-paper {
    @media (min-width: ${screenSizes.mediaM}px) {
      width: 300px;
    }
    @media (min-width: ${screenSizes.mediaXL}px) {
      width: 355px;
    }
  }
  .margin-padding-0_v-center {
    display: flex;
    align-items: center;
    margin: 2px 0!important;
    padding: 0!important;
  }
  .img_wrapper_grid {
    display: flex;
    align-items: center;
    justify-content: center;
  }
    .img_div_wrapper {
        width: 100%;
        max-width: 100%;
        display: flex;
        justify-content: center;
    }

    span.buyer_guide_title_sub {
        letter-spacing: 0.01em;
        color: #747574;
        font-family: Inter,Arial,sans-serif;
        line-height: 1.5rem;
        font-weight: 600;
        font-size: 1rem;
        margin-bottom: 16px;
    }

    .category_sub_div {
        // background-color: #c5c5c5;

        div.category_item_wrapper {
            height: 100%!important;
            padding: 15px;
            background: #fff;
            border-radius: 10px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
            box-sizing: border-box;
        }

        div.category_img_holder {
            width: 100%;
            aspect-ratio: 16 / 9;
            overflow: hidden;
            border-radius: 10px;
            margin-bottom: 10px;

            img {
                width: 100%;
                height: 100%;
                // object-fit: cover;
                object-fit: contain;
                border-radius: 0.375rem;
                max-width: 100%;
                vertical-align: middle;
                box-sizing: border-box;
            }
        }

        h3.category_item_title_h3 {
            color: #716e76;
            font-family: GT Pressura,sans-serif;
            font-size: 21px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: -0.18px;
            margin-top: 0;
            margin-bottom: .5rem;
            line-height: 1.2;
            box-sizing: border-box;

            // > a {
            //     text-decoration: none !important;
            //     outline: none;
            //     box-sizing: border-box;
            //     color: #716e76;
            // }

            // > a:hover {
            //      color: #5B31DD;
            // }
        }

        // h3.category_item_title_h3:hover {
        //     color: #5B31DD;
        // }

        ul.category_item_list_unstyled {
            margin: 0;
            padding: 0;
            margin-bottom: .5rem !important;
            list-style: none;
            box-sizing: border-box;

            li {
                list-style: none;
                box-sizing: border-box;

                > a {
                    color: #000000;
                    text-decoration: none !important;
                    -webkit-tap-highlight-color: transparent;
                    -webkit-touch-callout: none;
                    outline: none;
                    display: block;
                    font-size: 15px;
                    line-height: 20px;
                    transition: linear all 0.2s;
                    padding: 4px 0;
                    box-sizing: border-box;
                }

                > a:hover {
                    color: #5B31DD;
                    font-size: 16px;

                }
            }
        }
    }

`
const ShortSpan = styled.span`
    cursor: pointer!important;
    font-family: sans-serif;
    color: gray;
    &:hover {
        color: black;
    }
    &.lastSpan {
        // color: #1e947e!important;
    }
`
const CategoryTitle = styled.span`
    font-family: GT Pressura,sans-serif;
    color: black!important;
    font-weight: 600;
    font-size: 32px;
`
const UpdatedSpan = styled.span`
    font-family: Inter,Arial,sans-serif;
    color: gray!important;
    font-size: 15px;
    line-height: 22px;
`

const TabTitle = styled.span`
    font-family: GT Pressura,sans-serif;
    color: black!important;
    font-weight: 600;
    font-size: 22px;
`
export default Base_New