import * as React from 'react';
import styled from 'styled-components'
import { TableCell, ListItemButton, TableRow } from '@mui/material'
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import Switch from '@mui/material/Switch';

export default function CustomTableRowComp(props:any) {

    const {index, item, clicked_row, clicked_edit_item, clicked_delete_item, clicked_pos_up, clicked_pos_down, change_tag_option_status } = props
    const [tag_checked, setTagChecked] = React.useState(true);

    React.useEffect(() => {
        console.log(item.tag_option)
        if (item.tag_option === undefined || item.tag_option === false) {
            setTagChecked(false)
        } else if (item.tag_option === true) {
            setTagChecked(true)
        }
        
    }, [item])


    const handleTagSwitchChange = (e: any, index: number) => {
        e.stopPropagation()
        setTagChecked(e.target.checked);
        console.log(e.target.checked)
        change_tag_option_status(index, e.target.checked)
    };

    const clickedTagSwitch = (e: any) => {
        e.stopPropagation()
    };

    return (
        <> 
            <CustomTableRow
                hover
                // key={index}
                className={"row_style"}
                onClick={(e:any) => {clicked_row(e, index, item)}}
            >
                <CustomTableCell component="th" scope="row">
                    {item.DetailPageURL.length > 0 && item.DetailPageURL.slice(0, 70) + "..."}
                </CustomTableCell>
                <CustomTableCell align="right"><CustomListItemButton onClick={(e:any) => {clicked_edit_item(e, index, item)}} className={"edit_cell"}>{"edit"}</CustomListItemButton></CustomTableCell>
                <CustomTableCell align="right"><CustomListItemButton onClick={(e:any) => {clicked_delete_item(e, index, item)}} className={"delete_cell"}>{"delete"}</CustomListItemButton></CustomTableCell>
                <CustomTableCell align="right">
                    <Switch
                        checked={tag_checked}
                        onChange={(e:any) => {handleTagSwitchChange(e, index)}}
                        onClick={(e:any) => {clickedTagSwitch(e)}}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </CustomTableCell>
                <CustomTableCell align="right"><CustomListItemButton onClick={(e:any) => {clicked_pos_up(e, index, item)}} className={"arrow"}><KeyboardDoubleArrowUpIcon /></CustomListItemButton></CustomTableCell>
                <CustomTableCell align="right"><CustomListItemButton onClick={(e:any) => {clicked_pos_down(e, index, item)}} className={"arrow"}><KeyboardDoubleArrowDownIcon /></CustomListItemButton></CustomTableCell>
            </CustomTableRow>
        </>
        
    );
}

const CustomTableRow = styled(TableRow)`
    &.row_style {
        cursor: pointer!important;
    }
`

const CustomTableCell = styled(TableCell)`
    
`

const CustomListItemButton = styled(ListItemButton)`
    &.image_save_btn {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &.active_list_item {
        background-color: #1e947e;
        color: white;

    }
    &.edit_cell {
        display: flex;
        justify-content: center;
    }
    &.delete_cell {
        display: flex;
        justify-content: center;
    }
    &.edit_cell:hover {
        color: #009fff;
        background-color: #0db9f51f;
    }
    &.delete_cell:hover {
        color: #ff6000;
        background-color: #ff60002e;
    }

    &.add_category_icon {
        display: flex;
        justify-content: center;
        color: #1e947e;
    }

    &.add_item_icon{
        display: flex;
        justify-content: center;
        color: #1e947e;
    }
    &.add_category_icon: hover {
        color: #1e947e;
    }

    &.add_item_icon: hover {
        color: #1e947e;
    }

    &.arrow {
        display: flex;
        justify-content: center;
    }
`
